@import 'mixins';
@import 'pod-styles';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import 'ember-searchable-select/style';
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v10/jizaRExUiTo99u79D0-ExcOPIDUg-g.woff2)
      format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v10/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2)
      format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v10/jizaRExUiTo99u79D0yExcOPIDUg-g.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
    url(https://fonts.gstatic.com/s/ptsans/v10/jizaRExUiTo99u79D0KExcOPIDU.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'PT Sans';
}

[data-ember-action] {
  cursor: pointer;
}
b {
  font-weight: bold;
}
html,
body {
  height: 100%;

  margin: 0;
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 300;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}

input:required {
  box-shadow: none;
}

input:invalid {
  box-shadow: none;
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

a {
  text-decoration: none;
  color: white;
}

.app {
  // height: calc(100vh - 20vh);
  max-width: 1024px;
  margin: 0 auto 0;
  position: relative;
}
.isModulesFullWidth .app,
.is--desktop .app-main {
  max-width: 100%!important;
}
.is--mobile .footer-nav {
  display: none;
}

.is--desktop .footer-nav {
  position: absolute;
  bottom: -70px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: var(--background-nav);
  div {
    margin: 0 10px 0px;
  }
  a {
    color: var(--text-color-on-transparent-bg);
  }
  color: var(--text-color-on-transparent-bg);
}
.correct-margin-profil {
  margin: 0px 10px;
}
.is--desktop .correct-margin-profil {
  margin: 0px 20px;
}
.is--mobile.sticky-nav {
  .tabs {
    top: 50px;
    position: fixed;
  }

  .app {
    margin-top: 100px;
  }
}

// .app-main {
//   background:var(--primary-bg-color-2);
//   height: calc(100vh - 15vh - 0px);
// }

.is--mobile.sticky-nav {
  .app-header {
    position: fixed;
    top: 0;
    /* left: 0; */
    max-width: 1024px;
    /* margin: 0 auto 0; */
    /* left: 0%; */
  }

  .twitter-filter {
    position: fixed;
    top: 50px;
    z-index: 200;
  }
}

.welcome-message {
  //background: var(--primary-bg-color-2);
  color: white;
}

.clear {
  clear: both;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
* {
  -ms-overflow-style: none;
}

.devider {
  background: rgba(255, 255, 255, 0.3);
  height: 1px;
  width: 100%;
  margin: 10px 0px;
}

.header-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 29vh;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: -50px;

  .header-image-inner {
    color: white;
  }
}

.welcome-message {
  font-size: 25px;
  text-align: center;
  padding: 10px 10px;
}

.back-icon {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 10px;
  -webkit-mask-image: url(/icons/icons-back.svg);
  mask-image: url(/icons/icons-back.svg);
  mask-size: contain;
  -webkit-mask-size: contain;
  top: 8px;

  &.is--desktop {
    display: none;
  }
}

.black-text p,
.black-text strong {
  color: black !important;
}

.app-content {
  width: 100%;
  padding: 10px;
}

.streams {
  padding: 20px;
  video {
    width: 100%;
  }
  .vid1-dimensions {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
  .Searchable-select__search {
    display: none;
  }
}
.nav-main {
  background-image: linear-gradient(
    var(--background-nav-2),
    var(--background-nav-2)
  );
  height: calc(80vh - 50px);
  color: white;
  width: 100%;

  display: flex;
  padding-top: 20%;
  padding-left: 50px;
  flex-direction: column;
  text-align: center;
  z-index: 99999;
  margin-top: 50px;
  position: absolute;
  height: calc(100vh - 50px);

  .nav-item {
    display: flex;
    margin: 5px 0px;
    -webkit-animation: fadein 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s;
    /* Firefox < 16 */
    -ms-animation: fadein 1s;
    /* Internet Explorer */
    -o-animation: fadein 1s;
    /* Opera < 12.1 */
    animation: fadein 1s;
  }

  .nav-divider {
    border-bottom: 1px solid #ccc;
    height: 1px;
    width: 50vw;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

.is--mobile .sticky-nav {
  .nav-main {
    top: 50px;
    position: fixed;
    margin-top: 0px;
  }
}

.is--desktop {
  .break {
    flex-basis: 100%;
    height: 0;
  }

  .app-programm-item {
    flex: 0 0 49.5%;
  }

  .app-info-item {
    flex: 0 0 49.5%;
  }

  .app-programm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .app-info {
    display: flex;
    justify-content: space-between;
  }

  .app-main {
    max-width: 1024px;
    min-height: 60vh;
    margin: 0 auto;
  }
  .header-image {
    min-height: 200px;
    margin: 0 auto -50px;
  }

  .header-image {
    min-height: 200px;
    margin: 0 auto -50px;
  }

  .app-nav {
    display: none;
  }
}

.position-relative {
  position: relative;
}

.is--desktop {
  .minilogo {
    left: -50px;
    top: -8px;
  }
}

.minilogo {
  position: absolute;
  height: 35px;
  left: 25%;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.is--mobile .sticky {
  .minilogo {
    opacity: 1;
  }
}

.dashboard-item-link-chat::after,
.dashboard-item-link-novote::after {
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background: black;
  color: white;
  font-size: 11px;
  text-align: center;
  line-height: 10px;
  align-items: center;
  justify-content: center;
}

.dashboard-item-link-chat,
.dashboard-item-link-novote {
  position: relative;
}

.vote-not-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  > div {
    background: white;
    padding: 1.5rem;
    border-radius: 10px;
    color: #000000;
    button {
      margin-top: 1rem;
    }
  }
}

.form-control {
  border-radius: 5px;
  border: none;
  padding: 10px;
  width: 100%;
  margin: 20px 0px 20px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-success {
  color: #000000;
  background-color: #00d11c;
  border-color: #00af17;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;

  padding-right: 0px;
  padding-left: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background: var(--background-text-box);
  border-radius: 50%;
}

.container-checkbox .checkmark {
  border-radius: 0%;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.row {
  display: flex;

  .col-50 {
    width: 50%;
  }
}

.avatar-container {
  display: flex;
  justify-content: flex-end;
}

.avatar-image {
  border-radius: 5px;
  max-width: 100px;
}

.no-avater {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  border: 1px solid var(--text-color-question);
  border-radius: 5px;
}

.edit-avatar {
  color: lightgrey;
  font-size: 10px;
  background: var(--text-color-question);
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 2px;
  border-bottom-right-radius: 5px;
}

.btn-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 45px;
}

.btn-group-lg {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  text-align: center;
  font-size: 16px;
  position: relative;
}

.btn:hover {
  cursor: pointer;
}

.btn.btn-primary {
  color: white;
}
.btn.btn-danger {
  color: white;
  background-color: #e74c3c;
}
.btn.btn-success {
  color: white;
  background-color: #16a34a;
}
.btn-card {
  color: var(--text-color-appointment);
  background-color: var(--background-appointment);
}

.btn.btn-lg {
  height: 50px;
}

.btn.btn-md {
  height: 25px;
  font-size: 14px;
}
.btn.btn-sm {
  font-size: 16px;
  padding: 2px 6px;
  border-radius: 6px;
}
.btn.btn-p {
  margin: 0;
  cursor: initial;
}

.btn.btn-fullwidth {
  width: 100%;
}

.btn.btn-align-right {
  position: absolute;
  right: 10px;
  top: 10px;
}

.fa {
  color: white;
  margin: 0 10px;
}
.deleteInput {
  right: 4px !important;
  top: 26px !important;
  position: absolute;
  font-size: 20px;
}
.btn:disabled {
  opacity: 0.7;
}
.private-message {
  position: absolute;
  right: 0px;
}
.chat-box-avatar {
  display: flex;
  align-self: flex-start;
  margin-top: -10px;
  margin-bottom: -10px;
  &.left {
    margin-left: -10px;
    margin-right: 10px;
    img {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
  &.right {
    margin-left: 10px;
    img {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  img {
    max-width: 75px;
    max-height: 75px;
  }
}
.deleteInput {
  right: 0px;
  top: 25px;
  position: absolute;
  font-size: 20px;
  i {
    color: var(--text-color);
  }
}

.app-programm-module {
  overflow: initial;
}
.ember-cli-notifications-notification__container {
  z-index: 9999999 !important;
}

.Searchable-select__option-label {
  color: black;
}

.flex {
  display: flex;
}
.mb-4 {
  margin-bottom: calc(4 * var(--base-unit));
}
.mb-8 {
  margin-bottom: calc(8 * var(--base-unit));
}
.icon-mr {
  margin: 0;
  margin-right: calc(2 * var(--base-unit));
}
.icon-ml {
  margin: 0;
  margin-left: calc(2 * var(--base-unit));
}
:root {
  --base-unit: 0.25rem;
}

// missing icons
.fa.fa-clock::before {
  content: '\f017';
}
.dashboard-item-link-Chat {
  position: relative;
}
.unread-messages {
  position: absolute;
  right: 0px;
  font-size: 30px;
}

.btn-notification {
  background-color: red;
  border-radius: 1.25rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: calc(50% - 50px);
  transform: translate(-20%, -20%);
  font-size: 10px;
  width: 20px;
  height: 20px;
  z-index: 9999;
}
.pm-messages {
  z-index: 999;
  position: absolute;
  right: 10px;
  top: -2px;
  font-size: 40px;
  &:hover {
    cursor: pointer;
  }
  .btn-notification {
    right: 0;
    top: 0;
    transform: translateY(5px);
  }
}
.already-logged-in {
  padding: 10px;
  button {
    appearance: none;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    display: flex;
    margin-top: 2rem;
    svg {
      height: 2rem;
      fill: var(--text-color);
    }
  }
  .dashboard-link {
    display: block;
    margin-top: 1rem;
    text-decoration: underline;
  }
}
.login-state {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  span {
    font-weight: bold;
  }
  button {
    appearance: none;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    display: flex;
    svg {
      height: 2rem;
      fill: var(--fill-navbar-icons);
      margin-top: 1rem;
    }
  }
  margin-bottom: 2rem;
}
.notification-envelope > svg {
  height: auto;
  width: 40px;
  margin: 0 10px;
}
.btn.btn-danger {
  background-color: red;
}
.chat-input-wrapper {
  height: 45px;

  input {
    height: 100%;
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }

  button {
    border: none;
    height: 100%;
    background: var(--text-color-akzent);
    color: #fff;
    padding: 0 10px;
  }
}
.chat-input-wrapper {
  height: 45px !important;
}
.chat-input-wrapper input {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
}
.nav-main .btn-notification {
  top: -7px;
  right: calc(33% - 51px);
  transform: none;

}

.app-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
